import { useTranslation } from "react-i18next"
import { capitalizeFirstLetter } from "../Helpers"

export default function PermissionError({ error, handleLogout }) {
  const containerStyles = {
    height: '100vh',
    width: '100vw'
  }

  const { t } = useTranslation()

  return (
    <div style={containerStyles}>
      <div className='text-center mx-auto my-5'>
        <div className='mb-5 d-inline-flex align-items-center'>
          <img src='/static/img/logo_website.png' alt='Logo Memomove' />
          <h4 className='ms-2 mb-0'>
            Memomove
          </h4>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mx-auto p-4 alert alert-danger bg-white">
        <h1 className="mb-4">
            {/* Something went wrong! */}
            { t('something went wrong')}
          </h1>
          <p>
            {error.message ? (
              <>
                { t(error.message) }
              </>
            ) : (
              <>
                { t('access denied') }
              </>
            )}
          </p>
          {/* <p>{ t('access denied') }</p> */}
          <div className="mt-4 d-flex align-items-center">
            <button onClick={() => handleLogout()} className='btn btn-primary'>
              { capitalizeFirstLetter(t('sign out')) }
            </button>

            <a href="mailto:info@memomove.eu" subject="Memomove Admin - Permission problems" className="ms-auto fs-small">
              { t('contact us') }
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
