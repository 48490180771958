import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../Helpers';

export default function CycleRouteRow({ cycleRoute }) {
  const runTestUrl = `/${cycleRoute.id}/play`
  const { t } = useTranslation('translation');

  return (
    <tr>
      <td>
        {cycleRoute.description}
        <div className="d-flex w-100">
          {cycleRoute.distance !== null && (
            <small className='d-block'>
              <b>{capitalizeFirstLetter(t('distance'))}:</b> {cycleRoute.internal_distance / 1000}km
            </small>
          )}
        </div>
      </td>
      <td>
        {cycleRoute.last_run}
        <div className='d-flex'>
          <small className=''>
            <b>{capitalizeFirstLetter(t('popularity'))}:</b> {cycleRoute.counter}
          </small>

          {cycleRoute.pois > 0 && (
            <small className='ms-auto'>
              <b>Pois:</b> {cycleRoute.pois}
            </small>
          )}
        </div>
      </td>
      <td>
        {cycleRoute.finished && (
          <Link to={runTestUrl} className='ms-2 btn btn-primary btn-sm'>
            {capitalizeFirstLetter(t('run'))}
          </Link>
        )}
      </td>
    </tr>
  )
}
