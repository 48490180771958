import {
  Routes,
  Route,
  // useLocation
} from 'react-router-dom'
import PermissionError from './Views/PermissionError'
import Loader from './Views/Loader'

import Header from './Header'
import CycleRoutes from './CycleRoutes/CycleRoutes'
// import NewRoutePlayer from './Player/RoutePlayer'
import RoutePlayer from './v2/Player/RoutePlayer'
import NotFound from './Views/NotFound'

import callApi from '../utils/ApiCall';

import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";

import i18n from "i18next";
import Backend from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next"
    //     }
    //   }
    // },
    // debug: true,
    debug: process.env.REACT_APP_ENV === 'dev' ? true : false,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default function Pages() {
  const { instance } = useMsal();
  const [ organisation, setOrganisation ] = useState(null)
  const [ user, setUser ] = useState(null)
  const [ checkingPermissions, setCheckingPermissions] = useState(true)
  const [ requestError, setRequestError] = useState(null)

  const [ containerClasses, setContainerClasses ] = useState('container py-4 d-flex flex-column h-100')
  const [ navPage, setNavPage ] = useState('Home')
  const [ headerClasses, setHeaderClasses ] = useState('p-3 border-bottom')

  // let location = useLocation();

  const { t, i18n } = useTranslation();

  function setPageClasses(sub, page = null) {
    const defaultContainerClasses = 'container py-4 d-flex flex-column h-100'

    setNavPage(sub)
    
    if (page === 'RoutePlayer') {
      setContainerClasses('h-100')
    } else {
      const classes = page === 'RoutePlayer' && this.state.containerClasses === defaultContainerClasses ? 'h-100' : defaultContainerClasses
      setContainerClasses(classes)
    }

    if (page === 'Create') {
      hideHeader()
    } else {
      showHeader()
    }
  }

  
  function hideHeader() {
    setHeaderClasses('d-none')
  }

  function showHeader() {
    setHeaderClasses('p-3 border-bottom')
  }

  const checkAuth = useCallback(async() => {
    return await callApi('/api/auth/check')
      .then(function (response) {
        return response.data
      })
      .catch(async function (error) {
        const errorResponse = await error.json()
        // console.error(errorResponse);
        return errorResponse
      });
  }, [])

  const checkAuthLevel = useCallback(async() => {
    const account = instance.getActiveAccount()
    if (!account) return

    const authData = await checkAuth()

    if (!authData.success || !authData.organisation || authData.account.isManager ) {
      handleError({ code: 403 })
    } else {
      setOrganisation(authData.organisation)
      setUser(authData.account)

      i18n.changeLanguage(authData.account.language_code)

      if (!authData.account.isActive || !authData.organisation.active) {
        handleError({
          code: 403,
          message: 'account inactive'
        })
        return
      }
    }
    setCheckingPermissions(false)
  }, [instance, checkAuth, i18n])


  useEffect(() => {
    if (checkingPermissions) checkAuthLevel()
  }, [checkingPermissions, checkAuthLevel])

  const handleLogout = () => {
    const logoutConfirm = window.confirm(t('confirm'))
    if (logoutConfirm) {
      instance.logoutRedirect();
    }
  };

  const handleError = (error) => {
    // console.log('Error: ', error);

    setRequestError(error)
    setCheckingPermissions(false)
  }

  function nav_link_classes(link) {
    return navPage === link ? 'nav-link px-2 text-secondary' : 'nav-link px-2'
  }

  if (checkingPermissions) {
    const loaderContainerStyles = {
      height: '100vh',
      width: '100vw'
    }
    return (
      <div className='d-flex align-items-center' style={loaderContainerStyles}>
        <Loader />
      </div>
    )
  } else {
    if (requestError) {
      return (
        <>
          <PermissionError
            error={requestError} 
            handleLogout={handleLogout}
          />
        </>
      )
    } else {
      return (
        <>
          <Header
            headerClasses={headerClasses}
            nav_link_classes={nav_link_classes}
            organisation={organisation}
            user={user}
            handleLogout={handleLogout}
          />
          <div className={containerClasses}>
            <Routes>
              <Route
                index path="/*"
                element={
                  <CycleRoutes
                    setPageClasses={setPageClasses}
                    organisation={organisation}
                    t={t}
                  />
                }
              />
              <Route
                path=':id/play'
                element={
                  <RoutePlayer
                    setActiveNav={setPageClasses}
                  />
                }
              />
              <Route path="/*" element={
                <CycleRoutes
                  setPageClasses={setPageClasses}
                  organisation={organisation}
                  t={t}
                />
              }/>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </>
      )
    }
  }
}
